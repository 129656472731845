<template>
  <div class="contact">
    <div class="aboutOurCompany">
      <div class="title"><h3>الإدارة العامة للتدريب</h3></div>

      <v-divider></v-divider>
      <v-row>
        <v-img
      lazy-src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Loader.gif/480px-Loader.gif"
          src="@/assets/Images/46.jpg"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row> </template
        ></v-img>
      </v-row>
      <hr class="ma-3 fade-in-right center" justify="center" />
      <v-row class="mt-10 container justify-center">
        <v-col class="subClass" cols="12" sm="12" xs="12" lg="9">
          <h3>
            {{ items.title }}
          </h3>
        </v-col>
      </v-row>
      <v-row class="container" v-animate-onscroll.repeat="'fade-in-right'">
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel
            v-for="II in items.arrayOfTraning"
            :key="II"
            class="changeTitleStyle"
          >
            <v-expansion-panel-title
              expand-icon="mdi-menu-down"
              class="container"
            >
              {{ II }}
            </v-expansion-panel-title>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  scrollToTop() {},
  data() {
    return {
      model: null,
      userId: this.$route.params.userId,
    };
  },
  methods: {
    checkID() {
      this.userId = this.$route.params.userId;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    items() {
      // return this.$store.dispatch('findSelectedItem')
      return this.$store.state.departmentTraning.find(
        (item) => item.id == this.userId
      );
    },
   
  },
  watch: {
    $route: "checkID",
  },
};
</script>
<style >
h2 {
  width: 600px !important;
}
.subtextnews {
  font-size: 15px;
}
.changeTitleStyle {
  background: rgba(222, 184, 135, 0.192) !important;
  padding: 10px;
  margin: 5px;
}
</style>